/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React, { useContext, useEffect } from "react";
import { graphql } from "gatsby";
import { AppContext } from "~context/AppContext";
import { DocumentContext } from "~context/DocumentContext";
import Layout from "~components/Layout";
import SEO from "~components/SEO";
import { splitStringToParagraphs } from "~utils/helpers";

const InfoPage = ({ data, location }) => {
  const { setBackground } = useContext(AppContext);
  const { device } = useContext(DocumentContext);
  const { frontmatter } = data.markdownRemark;

  //

  useEffect(() => {
    setBackground(`white`);
  }, []);

  //

  return (
    <>
      <SEO
        customTitle={frontmatter.title}
        customDescription={frontmatter.seoDescription}
        customKeywords={frontmatter.seoKeywords}
        path={location.pathname}
      />

      <Layout className="info-page w-full relative pt-32">
        <section className="grid animation-appear-down-slow animation-delay-3">
          <article className="grid-end-6 md:grid-end-8 xs:grid-end-12 xs:mt-4">
            {splitStringToParagraphs(frontmatter.intro, `b1 b1--serif mb-4`)}
          </article>
        </section>

        {/* // */}

        {frontmatter.tables.map((table, tableIndex) => {
          const tableKey = `table-${tableIndex}`;

          return (
            <section
              key={tableKey}
              className="animation-appear-down-slow relative mt-8"
              style={{ animationDelay: `${tableIndex * 100 + 500}ms` }}
            >
              <header className="grid pt-10">
                <h3
                  className={`grid-end-12 ${
                    device === `mobile` ? `f2 mb-2` : `caption font-bold`
                  } uppercase`}
                >
                  {table.heading}
                </h3>
              </header>

              <article className="w-full relative caption">
                {device !== `mobile` && (
                  <header className="grid">
                    <h4 className="grid-end-5">{table.columnHeadingLeft}</h4>
                    <h4 className="grid-end-1">{table.columnHeadingMiddle}</h4>
                    <h4 className="grid-end-6">{table.columnHeadingRight}</h4>
                  </header>
                )}

                {table.rows.map((tableRow, tableRowIndex) => {
                  const tableRowKey = `${tableKey}-${tableRowIndex}`;

                  return (
                    <div key={tableRowKey}>
                      <div className="w-full relative border-b-black"></div>

                      <ul className="grid xs:py-4">
                        <li className="grid-end-5 xs:grid-end-12 xs:-mt-1 xs:underline">
                          {tableRow.left}
                        </li>

                        <li className="grid-end-1 xs:grid-end-12 xs:-mt-1">
                          {tableRow.middle}
                        </li>

                        <li className="grid-end-6 xs:grid-end-12 xs:-mt-1">
                          {tableRow.right}
                        </li>
                      </ul>
                    </div>
                  );
                })}

                <div className="w-full relative border-b-black"></div>
              </article>
            </section>
          );
        })}
      </Layout>
    </>
  );
};

export default InfoPage;

export const query = graphql`
  query InfoPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        intro
        tables {
          heading
          columnHeadingLeft
          columnHeadingMiddle
          columnHeadingRight
          rows {
            left
            middle
            right
          }
        }
        seoDescription
        seoKeywords
      }
    }
  }
`;
